<template>
    <div class="row">
        <LeftSection :list_check_box="list_check_box" :links="data.data_links" />

        <!-- Right Section -->
        <div class="col-12 col-lg-9 wrapper-box-fiche">
            <template v-for="(item, key) in list_check_box"> 
                <div class="box-white-fiche" :key="key" v-if="show_block.includes(key) && item.enable" :id="key">
                    <!-- Section Title Start -->
                    <div class="box-icon-openbar">
                        <div class="box-click-open" @click="item.show = !item.show">
                            <h3 :class="item.orange_text ? 'text-orange' : ''" style="margin-bottom: 5px;">{{ $t(item.title) }}</h3>
                            <em :class="item.show ? 'fa fa-pull-right fa-angle-up arrow-up' : 'fa fa-pull-right fa-angle-down arrow-down'"></em>
                        </div>
                        <div class="clearfix"></div>
                    </div>
                    <!-- Section Title End -->

                    
                    <!-- Section Detail Start -->
                    
                    <!-- Key Document start-->
                    <KeyDocument v-if="key=='detail_key_documents' && item.show" :data="data.key_documents" :list_document_exist="data.list_document_exist"/>
                    <!-- Key Document end-->

                    <!-- ATU start -->
                    <template  v-else-if="key == 'detail_ATU' && item.show">
                        <template v-for="(item_atu, key_atu) in data.transparence.atu_france" :key="'key_atu_1_'+key_atu">
                            

                            <!-- When Motif ATU = 'AAP' -->
                            <table class="comparateur2" v-if="item_atu.motif === 'AAP'"> 
                                <thead>
                                    <tr>
                                        <th width="25%"> {{$t("Motif de l'accès précoce")}} </th>
                                        <th width="25%"> {{$t('atufrance.HTA')}}  </th>
                                        <th width="25%"> {{$t('atufrance.Reimbursement')}} </th>
                                    </tr>
                                </thead>                                   
                                <tbody>
                                    <tr>
                                        <td>{{ item_atu.motif ? $t('atu_motif.'+item_atu.motif) : "-" }}</td>
                                        <td> 
                                            {{item_atu.drug_centers.hta_decision ? $t('atufrance.hta.'+item_atu.drug_centers.hta_decision) : '-'}}
                                            <br>
                                            {{item_atu.drug_centers.hta_process_date ? DDMMMYY(item_atu.drug_centers.hta_process_date) : ''}} 
                                        </td>
                                        <td> 
                                            {{item_atu.drug_centers.reimbursement_decision ? $t('atufrance.rb.'+item_atu.drug_centers.reimbursement_decision) : '-'}}
                                            <br>
                                            {{item_atu.drug_centers.rembursement_process_date ? DDMMMYY(item_atu.drug_centers.rembursement_process_date ) : ''}} 
                                        </td>
                                    </tr>
                                </tbody>
                            </table>

                            <table class="comparateur2" v-else> 
                                <thead>
                                    <tr>
                                        
                                        <th width="25%"> {{$t("Motif de l'accès précoce")}} </th>
                                        <th width="25%"> {{$t('date_granting')}} </th>
                                        <th width="25%"> {{$t('date_start')}} </th>
                                        <th width="25%"> {{$t('date_end')}} </th>
                                    </tr>
                                </thead>                                   
                                <tbody>
                                    <tr>
                                        <td>{{ item_atu.motif ? $t('atu_motif.'+item_atu.motif) : "-" }}</td>
                                        <td> {{item_atu['date_start'] ? DDMMMYY(item_atu['date_start']) : '-'}} </td>
                                        <td> {{item_atu['date_atu_start'] ? DDMMMYY(item_atu['date_atu_start']) : '-'}} </td>
                                        <td> {{item_atu['date_end'] ? DDMMMYY(item_atu['date_end']) : '-'}} </td>                                        
                                    </tr>
                                </tbody>
                            </table>
                        
                            <table class="comparateur2" v-if="$i18n.locale == 'fr' && item_atu.indication_atu_fr ||
                                $i18n.locale != 'fr' && item_atu.indication_atu_en"  :key="'key_atu_4_'+key_atu">
                                <thead>
                                    <tr>
                                        <th colspan="6" width="100%" class="first last" > 
                                            <template v-if="$i18n.locale === 'fr'">
                                                <template v-if="item_atu.motif == 'AAP'">{{$t("Indication de l'AAP")}}</template>
                                                <template v-else-if="item_atu.motif == 'AAC'">Indication de l’accès précoce</template>
                                                <template v-else>Indication retenue dans l’ATU</template>
                                            </template>
                                            <template v-else>Indication</template>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td colspan="6"  style="text-align: justify;"> 
                                            <span v-html="$i18n.locale == 'fr' ? item_atu['indication_atu_fr'] : item_atu['indication_atu_en']"></span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <div class="text-center" v-if="access_agency.includes('AtuFrance')">
                                <router-link :to="'/detail/'+item_atu.id+'/atu'" class="btn btn-orange" target="_blank" rel="noopener">
                                    {{$t('read more')}}
                                </router-link>  
                            </div>
                            <hr v-if="key_atu < (data.transparence.atu_france.length -1)" style="height: 4px;" />
                        </template>
                       
                        
                        <!-- <div class="collapse" id="collapseAtu">
                        <div class="card card-body">
                            Some placeholder content for the collapse component. This panel is hidden by default but revealed when the user activates the relevant trigger.
                        </div>
                        </div>  -->
                    </template>
                    <!-- ATU end -->

                    <!-- Post-ATU start -->
                    <template v-else-if="key == 'detail_PostAtu' && item.show">
                        <template v-for="(item_atu, key_atu) in data.transparence.atu_france" :key="key_atu">
                        <template v-for="(item_post_atu, key_post_atu) in item_atu.post_atu" :key="'key_postatu_1_'+key_post_atu">
                            <table class="comparateur2" >
                                <thead>
                                    <tr>
                                        <th width="33%" class="first" > {{$t('date')}} </th>
                                        <th width="33%" class="" > {{$t('avis_post')}} </th>
                                        <th width="33%" class="last" > {{$t('annexe_post')}} </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td><strong> {{item_post_atu['date'] ? DDMMMYY(item_post_atu['date']) : '-'}} </strong></td>
                                        <td>
                                            <template v-if="item_post_atu['avis_post_pdf']">
                                                <LinkUpload :href="'/uploads/AtuFrancePostAtu/'+item_post_atu.id+'/avis_post_pdf.pdf'" class="pdf" target="_blank" rel="noopener">PDF</LinkUpload>
                                            </template>
                                            <template v-else> - </template>
                                        </td>
                                        <td> 
                                            <template v-if="item_post_atu['annexe_post_pdf']">
                                                <LinkUpload :href="'/uploads/AtuFrancePostAtu/'+item_post_atu.id+'/annexe_post_pdf'" class="pdf" target="_blank" rel="noopener">PDF</LinkUpload>
                                            </template>
                                            <template v-else> - </template>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <table class="comparateur2" >
                                <thead>
                                    <tr>
                                        <th width="50%" class="first"> {{$t('ma_indication')}} </th>
                                        <th width="50%" class="last" > {{$t('identification_atu')}} </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td> {{$t('atu_indication_amm_'+item_post_atu['indication_amm'])}} </td>
                                        <td> {{$t('yes_no_'+item_post_atu['identification'])}} </td>
                                    </tr>
                                </tbody>
                            </table>
                            <table class="comparateur2" v-if="item_post_atu['indication_amm']" >
                                <thead>
                                    <tr>
                                        <th colspan="6" width="100%" class="first last" style="border:none"> {{$t('indication_amm')}} </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td colspan="6" style="text-align: justify;border-top:none;"> 
                                            <span v-html="$i18n.locale == 'fr' ? item_post_atu['indication_amm_fr'] : item_post_atu['indication_amm_en']"></span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>

                            <table class="comparateur2 " v-if="item_post_atu['post_atu_alternative'].length > 0 || item_post_atu['alternative_disponible']">
                                <thead>
                                    <tr>
                                        <th colspan="8" width="100%" class="first last" > {{$t('post_atu_alternative')}} </th>
                                    </tr>
                                </thead>
                                <thead v-if="item_post_atu['post_atu_alternative'].length > 0">
                                    <tr>
                                        <th width="5%" class="first" >&nbsp;</th>
                                        <th width="13%"> {{$t('TRS_nom')}} </th>
                                        <th width="13%"> {{$t('TRS_DCI')}} </th>
                                        <th width="13%"> {{$t('TRS_ATC_class')}} </th>
                                        <th width="13%"> {{$t('TRS_date')}} </th>
                                        <th width="13%"> {{$t('SMR')}} </th>
                                        <th width="17%" v-if="0"> {{$t('SMR_text')}} </th>
                                        <th width="13%" class="last"> {{$t('ASMR')}} </th>
                                    </tr>
                                </thead>
                                    <tbody>
                                        <tr v-for="(item_post_atu_alternative, key_post_atu_alternative) in item_post_atu['post_atu_alternative']" :key="'key_post_atu_alternative_'+key_post_atu_alternative">
                                            <td :class="item_post_atu_alternative.length > 1 && key < item_post_atu_alternative.length - 1 ? 'bg-white' : 'bg-white no-border-bottom'">
                                                <span v-if="item_post_atu_alternative.trs_id">
                                                    <a :href="'/detail/'+item_post_atu_alternative['trs_id']+'/trs'" target="_blank" rel="noopener">
                                                        <em class="icons icon-plus-m"></em>
                                                    </a>
                                                </span>
                                            </td>
                                            <td :class="item_post_atu_alternative.length > 1 && key < item_post_atu_alternative.length - 1 ? 'bg-white' : 'bg-white no-border-bottom'">
                                                {{ item_post_atu_alternative.transparence_smr.transparence && item_post_atu_alternative.transparence_smr.transparence['trs_specialities'] ? item_post_atu_alternative.transparence_smr.transparence['trs_specialities'] : '-'}}
                                            </td>
                                            <td  :class="item_post_atu_alternative.length > 1 && key < item_post_atu_alternative.length - 1 ? 'bg-white' : 'bg-white no-border-bottom'">
                                                {{ item_post_atu_alternative.transparence_smr.transparence && item_post_atu_alternative.transparence_smr.transparence['trs_dci'] ? item_post_atu_alternative.transparence_smr.transparence['trs_dci'] : '-' }}
                                            </td>
                                            <td  :class="item_post_atu_alternative.length > 1 && key < item_post_atu_alternative.length - 1 ? 'bg-white' : 'bg-white no-border-bottom'">
                                                <template v-if="item_post_atu_alternative.transparence_smr.transparence && item_post_atu_alternative.transparence_smr.transparence.transparence_atc && item_post_atu_alternative.transparence_smr.transparence.transparence_atc[0]['trsa_atc']">
                                                    <template v-if="item_post_atu_alternative.transparence_smr.transparence.transparence_atc[0]['trsa_atc'].length > 4">
                                                        {{item_post_atu_alternative.transparence_smr.transparence.transparence_atc[0]['trsa_atc'].substr(0, 4)}} <br> {{item_post_atu_alternative.transparence_smr.transparence.transparence_atc[0]['trsa_atc'].substr(4)}}
                                                    </template>
                                                    <template v-else>
                                                        {{item_post_atu_alternative.transparence_smr.transparence.transparence_atc[0]['trsa_atc']}}
                                                    </template>
                                                </template>
                                                <template v-else> - </template>
                                            </td>
                                            <td :class="item_post_atu_alternative.length > 1 && key < item_post_atu_alternative.length - 1 ? 'bg-white' : 'bg-white no-border-bottom'">
                                                {{item_post_atu_alternative.transparence_smr.transparence && item_post_atu_alternative.transparence_smr.transparence['trs_date'] ? DDMMMYY(item_post_atu_alternative.transparence_smr.transparence['trs_date']) : '-'}} 
                                            </td>
                                            <td  :class="item_post_atu_alternative.length > 1 && key < item_post_atu_alternative.length - 1 ? 'bg-white' : 'bg-white no-border-bottom'">
                                                {{ item_post_atu_alternative.transparence_smr && item_post_atu_alternative.transparence_smr['smr_type'] ? $t('transparence.smr.'+item_post_atu_alternative.transparence_smr['smr_type']) : '-' }}
                                            </td>
                                            <td v-if="0" :class="item_post_atu_alternative.length > 1 && key < item_post_atu_alternative.length - 1 ? 'bg-white' : 'bg-white no-border-bottom'">
                                                <div class="expandable4 textJustify" v-if="item_post_atu_alternative.transparence_smr['smr_text']">
                                                    <span v-html="item_post_atu_alternative.transparence_smr && item_post_atu_alternative.transparence_smr['smr_text'] ? item_post_atu_alternative.transparence_smr['smr_text'] : '-' "> </span>
                                                </div>
                                            </td>
                                            <td :class="item_post_atu_alternative.length > 1 && key < item_post_atu_alternative.length - 1 ? 'bg-white' : 'bg-white no-border-bottom'">
                                                {{item_post_atu_alternative.transparence_smr && item_post_atu_alternative.transparence_smr['asmr_obtenue'] ? item_post_atu_alternative.transparence_smr['asmr_obtenue'] : '-'}}
                                            </td>
                                        </tr>    
                                    </tbody>
                                    <tbody v-if="$i18n.locale == 'fr' &&  item_post_atu['alternative_disponible_fr'] || $i18n.locale != 'fr' && item_post_atu['alternative_disponible_en']">
                                        <tr>
                                            <td colspan="8">
                                                <div style="text-align: justify;">
                                                    <span v-html="$i18n.locale == 'fr' ? item_post_atu['alternative_disponible_fr'] : item_post_atu['alternative_disponible_en']"></span>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                            </table>
                            <table class="comparateur2" v-if="$i18n.locale == 'fr' &&  item_post_atu['conclusion_fr'] || $i18n.locale != 'fr' && item_post_atu['conclusion_en']" >
                                <thead>
                                    <tr>
                                        <th colspan="6" width="100%" class="first last" > {{$t('conclusion_atu')}} </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td colspan="6" style="text-align: justify;"> 
                                            <span v-html="$i18n.locale == 'fr' ? item_post_atu['conclusion_fr'] : item_post_atu['conclusion_en']"></span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </template>                        
                        </template>

                    </template>
                    <!-- Post-ATU end -->

                    <!--Indication start-->
                    <Indication v-else-if="key == 'detail_indication' && item.show"/>
                    <!--Indication end-->

                     <!--Indication de l’AMM non concernée par la demande de prise en charge temporaire start-->  
                    <div class="content-onglets"  v-else-if="key == 'detail_indication_non_prise_en_charge_temporaire' && item.show">
                        <p v-if="$i18n.locale == 'fr'" v-html="data.transparence['indication_non_prise_en_charge_temporaire_fr']"></p>
                        <p v-else v-html="data.transparence['indication_non_prise_en_charge_temporaire_en']"></p>
                    </div>
                    <!--Indication de l’AMM non concernée par la demande de prise en charge temporaire end--> 

                    <!-- treatment_line start -->
                    <TreatmentLine v-else-if="key == 'treatment_line' && item.show"/>
                    <!-- treatment_line end -->

                    <!--MA spec start-->
                    <table class="comparateur2 text-left"  v-else-if="key == 'detail_general_information' && item.show"> 
                        <tr v-if="$i18n.locale == 'fr' && strippedContent(data.transparence.trs_cpd) || $i18n.locale != 'fr' && strippedContent(data.transparence.trs_cpd_en)">
                            <td width="15%" class="bold bg-white"><strong> {{$t('TRS_CPD')}} </strong></td>
                            <td class="bg-white" style="text-align: left;"> 
                                <span v-if="$i18n.locale == 'fr'" v-html="data.transparence.trs_cpd"></span>
                                <span v-else-if="$i18n.locale != 'fr'" v-html="data.transparence.trs_cpd_en"></span>
                            </td>
                        </tr>
                        <tr v-if="data.transparence.trs_date_depot_ct">
                            <td width="15%"  class="bold bg-white"><strong> {{$t('TRS_date_depot_ct')}} </strong></td>
                            <td class="bg-white">{{ data.transparence.trs_date_depot_ct ? DDMMMYY(data.transparence.trs_date_depot_ct) : '-' }}</td>
                        </tr>
                        <tr v-else-if="data.transparence.trs_date_validation_administrative">
                            <td width="15%"  class="bold bg-white"><strong> {{$t('Date de validation administrative')}} </strong></td>
                            <td class="bg-white">{{ data.transparence.trs_date_validation_administrative ? DDMMMYY(data.transparence.trs_date_validation_administrative) : '-' }}</td>
                        </tr>
                        <tr v-if="data.transparence.trs_type_amm">
                            <td width="15%"  class="bold bg-white"><strong> {{$t('TRS_AMM')}} </strong></td>
                            <td class="bg-white"> {{data.transparence.trs_type_amm ? $t('advance.TransparenceTC.type_amm.'+data.transparence.trs_type_amm) : '-'}} </td>
                        </tr>
                        <tr colspan="3" v-if="data.transparence.trs_date_type_amm">
                            <td width="15%"  class="bold bg-white"><strong> {{$t('TRS_date_type_AMM')}} </strong></td>
                            <td class="bg-white"> {{data.transparence.trs_date_type_amm ? DDMMMYY(data.transparence.trs_date_type_amm) : '-'}} </td>
                        </tr>
                        <tr colspan="3" v-else-if="data.transparence.nd">
                            <td width="15%"  class="bold bg-white"><strong> {{$t('TRS_date_type_AMM')}} </strong></td>
                            <td class="bg-white"> {{$t('Not available')}} </td>
                        </tr>
                        <tr v-if="data.transparence.trs_modify_smr_asmr"> 
                            <td width="15%"  class="bold bg-white"><strong> {{$t('TRS_modify_smr_asmr')}} </strong></td>
                            <td class="bg-white">  {{$t('yes_'+data.transparence.trs_modify_smr_asmr)}} </td>
                        </tr>

                        <tr v-if="$i18n.locale == 'fr' && data.transparence.trs_commentaires || $i18n.locale != 'fr' && data.transparence.trs_commentaires_en">
                            <td width="15%"  class="bold bg-white"><strong> {{$t('TRS_commentaires')}} </strong></td>
                            <td class="bg-white"> 
                                <div style="width:100%;text-align: justify;">  
                                    {{check_empty($i18n.locale == 'fr' ? data.transparence.trs_commentaires : data.transparence.trs_commentaires_en)}} 
                                </div> 
                            </td>
                        </tr>
                        <tr v-if="data.transparence.trs_demand_withdrawn">
                            <td width="15%"  class="bold bg-white"><strong> {{$t('TRS_demand_withdrawn')}} </strong></td>
                            <td class="bg-white"> {{$t('yes_1')}} </td>
                        </tr>
                        <tr v-if="data.transparence.trs_demand_retiree">
                            <td width="15%"  class="bold bg-white"><strong> {{$t("Demande retirée")}} </strong></td>
                            <td class="bg-white"> {{$t('yes_1')}} </td>
                        </tr>
                        <tr v-if="data.transparence.trs_resrtriction">
                            <td width="15%"  class="bold bg-white"><strong> {{$t("TRS_resrtriction")}} </strong></td>
                            <td class="bg-white"> {{$t('yes_1')}} </td>
                        </tr>
                        <tr v-if="data.transparence.trs_type_demandes">
                            <td width="15%"  class="bold bg-white"><strong> {{$t("Type de demande")}} </strong></td>
                            <td class="bg-white"> {{$t('transparence.trs_type_demandes.'+data.transparence.trs_type_demandes)}} </td>
                        </tr>
                        <tr colspan="3" v-if="data.transparence.trs_prise_en_charge_precoce">
                            <td width="15%"  class="bold bg-white"><strong> {{$t('Prise en charge précoce')}} </strong></td>
                            <td class="bg-white"> {{data.transparence.trs_prise_en_charge_precoce ? DDMMMYY(data.transparence.trs_prise_en_charge_precoce) : '-'}} </td>
                        </tr>
                    </table>            
                    <!--MA spec end-->

                    <!-- conclusion -->
                    <Conclusion v-else-if="key == 'detail_Conclusion' && item.show" />  

                     <!--Contexte start-->
                    <div class="content-onglets" v-else-if="key == 'detail_Context' && item.show">
                        <p v-if="$i18n.locale == 'fr'" v-html="data.transparence.trs_context_fr"> </p>
                        <p v-else v-html="data.transparence.trs_context_en"> </p>
                    </div>
                     <!--Contexte end-->

                    <!--Besoin medical start-->
                    <div class="content-onglets table" v-else-if="key == 'detail_besoin_medical' && item.show">  
                        <table class="comparateur2" v-for="(item_besoin_medical, key_besoin_medical) in data.transparence.transparence_smr[0].besoin_medical_json" :key="'key_besoin_medical_'+key_besoin_medical">
                            <tbody>
                                <tr>
                                    <td class="border_top" width="50%">
                                        <strong> {{ check_empty($i18n.locale == 'fr' ? item_besoin_medical['besoin_medical_title'] : item_besoin_medical['besoin_medical_title_en'])}}</strong>
                                    </td>
                                    <td class="border_top" style="vertical-align: middle;">
                                        <strong>{{ check_empty($t(item_besoin_medical['besoin_medical_select'])) }}</strong>
                                    </td>
                                </tr>
                                <tr v-if="($i18n.locale == 'fr' && item_besoin_medical['besoin_medical_text']) || ($i18n.locale != 'fr' && item_besoin_medical['besoin_medical_text_en'])">
                                    <td colspan="2" style="text-align: justify;">
                                        <span v-html="check_empty($i18n.locale == 'fr' ? item_besoin_medical['besoin_medical_text'] : item_besoin_medical['besoin_medical_text_en'])"></span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>   
                    </div>
                    <!--Besoin medical end-->

                    <!--data package start-->
                    <div class="content-onglets" v-else-if="key == 'detail_data_package' && item.show">
                        <p v-html="check_empty($i18n.locale == 'fr' ? data.transparence['data_package_fr'] : data.transparence['data_package_en'])"></p>
                    </div>
                    <!--data package end-->

                    <!--SMR start--> 
                    <div class="content-onglets table table-responsive click" v-else-if="key == 'detail_SMR' && item.show">
                        <div class="accordion accordion-table table-block-1200" id="accordion1">
                            <div class="accordion-group">
                                <table class="comparateur2 table-heading top" style="margin-bottom : 0;">
                                    <tbody>
                                        <tr>
                                            <th width="45%" style="border:none;">&nbsp;</th>
                                            <td width="18.33%"  class="bg-white text-center">SMR</td>
                                            <td width="18.33%"  class="bg-white text-center"> {{$t('Number of patients concerned')}} </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <div class="accordion-group" v-for="(item_smr, key_smr) in gen_smr(data.transparence.transparence_smr)" :key="'SMR_'+key_smr">
                                <table class="comparateur2 table-heading"  style="margin-bottom : 0;">
                                    <tbody>
                                        <tr>
                                            <th width="45%" class="bg-white text-left">
                                                {{ check_empty($i18n.locale == 'fr' ? item_smr.smr_title : item_smr.smr_title_en)}}
                                            </th>
                                            <td width="18.33%"  class="bg-white text-center">
                                                <div>{{item_smr.smr_type ? $t('transparence.smr.'+item_smr.smr_type) : '-'}}</div>
                                                <div>{{item_smr.smr_remboursement_non_demand ? $t('transparence.smr_remboursement_non_demand') : ''}}</div>
                                            </td>
                                            <td width="18.33%" class="bg-white text-center">
                                                <div>
                                                    <template v-for="(item_ndp, key_ndp) in item_smr.nombre_de_patient_json" :key="'key_ndp_'+key_ndp">
                                                        <span>
                                                            {{$i18n.locale === 'fr' ? item_ndp.NDP_text : item_ndp.NDP_text_en}}
                                                            <hr v-if="item_smr.nombre_de_patient_json.length > 1 && key_ndp < item_smr.nombre_de_patient_json.length - 1">
                                                        </span>
                                                    </template>

                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <a class="accordion-toggle text-blank " @click="SMR_text_toggle(key_smr)">
                                    <em :class="SMR_text_show[key_smr] ? 'icons-2014 icon-minus-s' : 'icons-2014 icon-plus-s'"></em>
                                </a>
                                <div v-if="SMR_text_show[key_smr] == 1" class="accordion-body " style="text-align:justify;overflow: hidden; background-color : #FEF0E6;">
                                    <span v-html="check_empty($i18n.locale == 'fr' ? item_smr['smr_text'] : item_smr['smr_text_en'])"></span>
                                </div>
                            </div>
                        </div>
                        <div class="clearfix"></div>
                    </div>
                    <!--SMR end--> 

                    <!--ASMR start--> 
                    <div class="content-onglets table click" v-else-if="key == 'detail_ASMR' && item.show">
                        <div class="accordion accordion-table table-block-1200" id="accordion2">                                
                            <div class="accordion-group">
                                <table class="comparateur2 table-heading top" style="margin-bottom: 0px;">
                                    <tbody>
                                        <tr>
                                            <th width="45%" class="no-border">&nbsp;</th>
                                            <td width="18.33%" class="bg-white text-center"> {{$t('asmr.ASMR_obtenue_short')}} </td>
                                            <td width="18.33%" class="bg-white text-center"> {{$t('asmr.ASMR_demande_short')}} </td>
                                            <td width="18.33%" class="bg-white text-center"> {{$t('SMR')}} </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <template v-for="(item_asmr, key_asmr) in gen_asmr(data.transparence.transparence_smr)" >
                                <div class="accordion-group" :key="'key_asmr_'+key_asmr" v-if="item_asmr['asmr_obtenue'] || item_asmr['asmr_demande']">
                                    <table class="comparateur2" style="margin-bottom:0px;">
                                        <tbody>
                                            <tr>
                                                <th width="45%" class="bg-white text-left">
                                                     {{check_empty($i18n.locale == 'fr' ? item_asmr['smr_title'] : item_asmr['smr_title_en'])}}
                                                </th>
                                                <td width="18.33%" class="bg-white text-center">
                                                    <div> {{check_empty($i18n.locale == 'fr' ? item_asmr['asmr_obtenue'] : item_asmr['asmr_obtenue_en'])}}</div></td>
                                                <td width="18.33%" class="bg-white text-center">
                                                    <div> {{item_asmr['asmr_demande'] ? item_asmr['asmr_demande'] : '-' }}</div></td>
                                                <td width="18.33%" class="bg-white text-center">
                                                    <div> {{item_asmr['smr_type'] ? $t('transparence.smr.'+item_asmr['smr_type']) : '-' }} </div></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <a @click="ASMR_text_toggle(key_asmr)">
                                        <em :class="ASMR_text_show[key_asmr] ? 'icons-2014 icon-minus-s' : 'icons-2014 icon-plus-s'"></em>
                                    </a>
                                    <div v-if="ASMR_text_show[key_asmr] == 1" class="accordion-body " style="text-align:justify;overflow: hidden; background-color : #FEF0E6; " >
                                        <span v-html="check_empty($i18n.locale == 'fr' ? item_asmr['asmr_text'] : item_asmr['asmr_text_en'])"></span>
                                    </div>
                                    <table class="comparateur2" style=" margin-bottom: 0px;" v-if="item_asmr['asmrcomparateur'] && item_asmr['asmrcomparateur'].length > 0 && (item_asmr['asmrcomparateur'][0]['text'] || item_asmr['asmr_comparateur'] != 'TRS_asmr_0')">
                                        <tbody>
                                            <tr v-if="item_asmr['asmrcomparateur'][0] && item_asmr['asmrcomparateur'][0]['text']">
                                                <th class="bg-white text-left" width="20%"> {{$t('comparaison')}} </th> 
                                                <td width="25.5%" class="bg-white txt-center bold" style="vertical-align: middle;">
                                                    <strong> {{item_asmr['asmr_comparateur'] ? $t(item_asmr['asmr_comparateur']) : '-'}} </strong>
                                                </td>   
                                                <td width="54.5%" class="bg-white bold" style="vertical-align: middle;">
                                                    <div style="text-align: justify;" v-for="(item_asmr_comparateur, key_asmr_comparateur) in item_asmr['asmrcomparateur']" :key="'key_asmr_comparateur_'+key_asmr_comparateur">
                                                        {{item_asmr_comparateur['text']}}
                                                    </div> 
                                                </td>   
                                            </tr> 
                                            <tr v-else-if="item_asmr['asmr_comparateur'] != 'TRS_asmr_0'">
                                                <th class="text-left gray " width="15%"> {{$t('comparaison')}} </th> 
                                                <td width="61%" class="bg-white bold" colspan="2">
                                                    <strong> {{item_asmr['asmr_comparateur'] ? $t(item_asmr['asmr_comparateur']) : '-'}} </strong>
                                                </td>
                                            </tr> 
                                        </tbody>
                                    </table>      
                                </div>
                            </template>
                        </div>
                        

                    </div>
                    <!--ASMR end--> 

                    <!--TransparenceStrategieTherapeutique start-->
                    <div class="content-onglets table" v-else-if="key == 'detail_TransparenceStrategieTherapeutique' && item.show">    
                        <template v-for="(item_transparence_stragie_therepeutique, key_transparence_stragie_therepeutique) in data.transparence.transparence_strategie_therapeutique">
                            
                            <table class="comparateur2 text-left" v-if="$i18n.locale == 'fr' && item_transparence_stragie_therepeutique['title'] || $i18n.locale != 'fr' && item_transparence_stragie_therepeutique['title_en'] || 
                                item_transparence_stragie_therepeutique['asmr_id']" :key="'key_transparence_stragie_therepeutique_'+key_transparence_stragie_therepeutique">
                                <thead> 
                                    <tr>
                                        <th>{{$t('Stratégie thérapeutique_2')}}</th>
                                        <th style=" width: 15%">ASMR</th>
                                    </tr>
                                </thead> 
                                <tbody> 
                                    <tr>
                                        <td style="text-align: left;">
                                            <div style="text-align: justify;" v-if="$i18n.locale == 'fr' && item_transparence_stragie_therepeutique['title']">
                                                {{item_transparence_stragie_therepeutique['title'] ? item_transparence_stragie_therepeutique['title'] : '-'}}
                                            </div>
                                            <div style="text-align: justify;" v-else-if="$i18n.locale != 'fr' && item_transparence_stragie_therepeutique['title_en']">
                                                {{item_transparence_stragie_therepeutique['title_en'] ? item_transparence_stragie_therepeutique['title_en'] : '-'}}
                                            </div>
                                        </td>
                                        <td style="text-align: center !important;">
                                            <template v-if="item_transparence_stragie_therepeutique['transparence_strategie_therapeutique_asmr']">
                                                <span :key="'key_transparence_stragie_therepeutique_asmr_'+key_transparence_strategie_therapeutique_asmr" 
                                                    v-for="(item_transparence_strategie_therapeutique_asmr, key_transparence_strategie_therapeutique_asmr) in item_transparence_stragie_therepeutique['transparence_strategie_therapeutique_asmr']">
                                                    {{item_transparence_strategie_therapeutique_asmr.asmr && item_transparence_strategie_therapeutique_asmr.asmr.asmr_obtenue ? item_transparence_strategie_therapeutique_asmr.asmr.asmr_obtenue : ""}}
                                                    <hr v-if="item_transparence_stragie_therepeutique['transparence_strategie_therapeutique_asmr'].length > 1 && key_transparence_stragie_therepeutique_asmr < item_transparence_stragie_therepeutique['transparence_strategie_therapeutique_asmr'].length - 1">
                                                </span>
                                            </template>
                                        </td>
                                    </tr> 
                                </tbody>
                            </table>            
                            <div style="text-align: justify;" v-if="$i18n.locale == 'fr' && item_transparence_stragie_therepeutique['text']" :key="'key_transparence_stragie_therepeutique_text_'+key_transparence_stragie_therepeutique">
                                <span v-html="item_transparence_stragie_therepeutique['text'] ? item_transparence_stragie_therepeutique['text'] : '-'"></span>
                            </div>
                            <div v-if="$i18n.locale != 'fr' && item_transparence_stragie_therepeutique['text_en']" :key="'key_transparence_stragie_therepeutique_text_en_'+key_transparence_stragie_therepeutique">
                                <span v-html="item_transparence_stragie_therepeutique['text_en'] ? item_transparence_stragie_therepeutique['text_en'] : '-'"></span>
                            </div>
                        </template>                    
                    </div>
                    <!--TransparenceStrategieTherapeutique end-->

                    <!-- DataBlock start -->
                    <template v-else-if="key == 'detail_DataBlock' && item.show">
                        <div class="content-onglets table click" v-for="(item_audition, key_audition) in data.transparence.audition" :key="'key_audition_'+key_audition">
                            <div class="accordion accordion-table table-block-1200">
                                <div class="accordion-group" style="margin-top: 0px;">
                                    <table class="comparateur2">      
                                        <tr>
                                            <td width="20%"  class="gray"> {{item_audition['audition_a_cocher'] ? $t(item_audition['audition_a_cocher']+'_2') : "-"}} </td>
                                            <td style="background-color: #FFFFFF;" class=" text-left" v-html="check_empty($i18n.locale == 'fr' ? item_audition['audition_text'] : item_audition['audition_text_en'])"> </td>
                                        </tr>    
                                    </table>
                                    <br/>

                                    <section class="accordion-heading" style="background-color: #FFFFFF">
                                        <div class="accordion-group">
                                            <table class="comparateur2 table-heading"  style="width:100%">
                                                <tbody>
                                                    <tr>
                                                        <td width="50%" style="text-align: left;"><strong> {{$t('Modification Audition')}} </strong></td>
                                                        <td width="50%" style="text-align: left;">  {{item_audition['modification_audition'] ? item_audition['modification_audition'] : '-'}} </td>
                                                    </tr>
                                                    <tr>
                                                        <td style="text-align: left;vertical-align: top"><strong> {{$t('Objet Audition')}} </strong><br>
                                                            <div>
                                                                <template v-if="item_audition['objet_audition']">
                                                                    <template v-for="(item_object_audition, key_object_audition) in item_audition['objet_audition']" :key="'key_object_audition_'+key_object_audition">
                                                                        <div class="yes-icon"></div> 
                                                                        <template v-if="item_object_audition == 'SMR'">{{$t('SMR_2')}}</template>
                                                                        <template v-if="item_object_audition == 'ASMR'">{{$t('ASMR_2')}}</template>
                                                                        <template v-if="item_object_audition == 'PC'">{{$t('Population cible_2')}}</template>
                                                                        <template v-if="item_object_audition == 'ST'">{{$t('Stratégie thérapeutique_2')}}</template>
                                                                        <template v-if="item_object_audition == 'AU'">{{$t('Autres_2')}}</template>
                                                                    </template>
                                                                </template>
                                                                <div style="width: 80%;display: inline-block;" v-else>-</div>
                                                            </div>
                                                        </td>
                                                        <td style="text-align: left; vertical-align: top"><strong> {{$t('Résultat Audition')}} </strong><br>
                                                            <div>
                                                                <template v-if="item_audition['result_audition']">
                                                                    <template v-for="(item_result_audition, key_result_audition) in item_audition['result_audition']" :key="'key_result_audition_'+key_result_audition" >
                                                                        <div class="yes-icon"></div> 
                                                                        <template v-if="item_result_audition == 'SMR'">{{$t('SMR_2')}}</template>
                                                                        <template v-if="item_result_audition == 'ASMR'">{{$t('ASMR_2')}}</template>
                                                                        <template v-if="item_result_audition == 'PC'">{{$t('Population cible_2')}}</template>
                                                                        <template v-if="item_result_audition == 'ST'">{{$t('Stratégie thérapeutique_2')}}</template>
                                                                        <template v-if="item_result_audition == 'AU'">{{$t('Autres_2')}}</template>
                                                                    </template>
                                                                </template>
                                                                <div style="width: 80%;display: inline-block;" v-else>-</div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <a @click="show_data_phase_contradictoire_toggle(key_audition)" class="accordion-toggle text-blank ">
                                                <em :class="show_data_phase_contradictoire[key_audition] ? 'icons-2014 icon-minus-s' : 'icons-2014 icon-plus-s'"></em>
                                            </a>
                                            <div v-if="show_data_phase_contradictoire[key_audition] == 1" class="accordion-body " style="border:none;text-align:justify;overflow: hidden;padding: 0px; background-color : #FEF0E6;" >
                                                <table class="table-heading"  style="width:100%">
                                                    <tr>
                                                        <td width="50%" style="text-align: justify;padding: 10px;vertical-align: top;">
                                                            <strong> Compte-Rendu initial :</strong><br>
                                                            <span v-html="item_audition['compte_rendu_initial'] ? item_audition['compte_rendu_initial'] : '-'"></span> 
                                                        </td>
                                                        <td width="50%" style="text-align: justify;padding: 10px;vertical-align: top;">
                                                            <strong> Compte-Rendu Audition :</strong><br>
                                                            <span v-html="item_audition['compte_rendu_audition'] ? item_audition['compte_rendu_audition'] : '-'"></span>
                                                        </td>
                                                    </tr>
                                                </table>
                                            </div>
                                        </div>
                                    </section><!--accordion-heading-->

                                </div><!--accordion-group-->
                                <div class="clearfix"></div>
                            </div>
                        </div>
                    </template>
                    <!-- DataBlock end -->

                    <!--Link comparateurs_pertinents start--> 
                    <template v-else-if="key == 'detail_comparateurs_pertinents' && item.show">
                        <div class="content-onglets table" >
                                
                            <table class="comparateur2" v-if="data.transparence.comparateurs_pertinent.length > 0">
                                <thead>
                                    <!-- <template v-for="(item_comparateurs_pertinent, key_comparateurs_pertinent) in data.transparence.comparateurs_pertinent">
                                        <tr v-if="item_comparateurs_pertinent.comparateurs_pertinent_smr['smr_title']" :key="'key_ComparateursPertinent_'+key_comparateurs_pertinent">
                                            <th colspan="6" class="first last" style="background:#ffffff;text-align: justify;">
                                                <p> {{item_comparateurs_pertinent.comparateurs_pertinent_smr['smr_title']}} </p>
                                            </th>
                                        </tr> -->
                                            <!-- </template> -->
                                        <tr>                    
                                            <th width="50" class="first" >&nbsp;</th>                      
                                            <th width="15%"> {{$t('TRS_nom')}} <br/> {{$t('TRS_DCI')}} </th>
                                            <th width="15%"> {{$t('TRS_ATC_class')}} </th>
                                            <th width="15%"> {{$t('TRS_date')}} </th>
                                            <th width="15%"> {{$t('SMR')}} / {{$t('ASMR')}} </th>
                                            <th class="last"> {{$t('Indication')}} </th>
                                        </tr>
                                </thead>
                                <tbody>
                                    <template v-for="(item_comparateurs_pertinent, key_comparateurs_pertinent) in data.transparence.comparateurs_pertinent">
                                        <tr v-for="(item_comparateurs_pertinent_trs, key_comparateurs_pertinent_trs) in item_comparateurs_pertinent.comparateurs_pertinent_trs" :key="key_comparateurs_pertinent+'comparateurs_pertinent_trs_'+key_comparateurs_pertinent_trs">
                                            <td style="vertical-align: middle;" >
                                                <span v-if="item_comparateurs_pertinent_trs['trs_id']">
                                                    <a :href="'/detail/'+item_comparateurs_pertinent_trs['trs_id']+'/trs'">
                                                        <em class="icons icon-plus-m"></em>
                                                    </a>
                                                </span>
                                            </td>
                                            <td>
                                                <template v-if="item_comparateurs_pertinent_trs['trs_specialities']">
                                                    {{item_comparateurs_pertinent_trs['trs_specialities']}} <br>
                                                </template>
                                                {{item_comparateurs_pertinent_trs['trs_dci'] ? item_comparateurs_pertinent_trs['trs_dci'] : '-'}}
                                            </td>
                                            <td style="padding: 0px;">    
                                                <template v-for="(item_transparence_atc, key_transparence_atc) in item_comparateurs_pertinent_trs.transparence_atc" :key="'key_transparence_'+key_transparence_atc" >
                                                    <span>{{item_transparence_atc['trsa_atc']}}</span>    
                                                </template>
                                            </td>

                                            <td>
                                                {{item_comparateurs_pertinent_trs['trs_date'] ? DDMMMYY(item_comparateurs_pertinent_trs['trs_date']) : '-' }}
                                            </td>

                                            <template v-if="Object.keys(gen_smr(item_comparateurs_pertinent_trs.transparence_smr)).length == 1">
                                            <td>
                                                {{$t('transparence.smr.'+item_comparateurs_pertinent_trs.transparence_smr[0].smr_type)}}
                                                <template v-for="(item_tc_asmr, key_tc_asmr) in item_comparateurs_pertinent_trs.transparence_smr">
                                                    <span v-if="item_tc_asmr['asmr_obtenue']" :key="'key_tc_asmr'+key_tc_asmr">
                                                        / {{item_tc_asmr['asmr_obtenue']}}
                                                    </span>
                                                </template>
                                            </td>
                                            <td>
                                                <template v-if="$i18n.locale == 'fr'">
                                                    {{item_comparateurs_pertinent_trs.transparence_smr[0].smr_title}}
                                                </template>
                                                <template v-else>
                                                    {{item_comparateurs_pertinent_trs.transparence_smr[0].smr_title_en}}
                                                </template>
                                            </td>
                                            </template>

                                            <template v-else>
                                                <td style="padding: 0px;" colspan="2">
                                                    <table class="comparateur2 mb-0 noborder" style="height:100%;width:100%;top:0px;left:0px;">
                                                        <tr v-for="(item_tc_smr, key_tc_smr) in gen_smr(item_comparateurs_pertinent_trs.transparence_smr)" :key="'key_tc_smr_'+key_tc_smr">
                                                            <td style="height: 100%; border-left: 0px; border-bottom: 0px; padding: 4px; width:160px;">
                                                                {{$t('transparence.smr.'+item_tc_smr.smr_type)}}
                                                                <template v-for="(item_tc_asmr, key_tc_asmr) in item_comparateurs_pertinent_trs.transparence_smr">
                                                                    <span v-if="item_tc_asmr['asmr_obtenue']" :key="'key_tc_asmr'+key_tc_asmr">
                                                                        / {{item_tc_asmr['asmr_obtenue']}}
                                                                    </span>
                                                                </template>
                                                            </td>
                                                            <td style="height: 100% border-left: 0px;border-right: 0px;border-bottom: 0px;padding: 4px;text-align:justify;">
                                                                <template v-if="$i18n.locale == 'fr'">
                                                                    {{item_tc_smr.smr_title}}
                                                                </template>
                                                                <template v-else>
                                                                    {{item_tc_smr.smr_title_en}}
                                                                </template>
                                                            </td>
                                                        </tr>
                                                    </table>
                                                </td>
                                            </template>
                                        </tr> 
                                    </template>
                                </tbody>
                            </table>
                            
                            <br/>
                            <div class="text textJustify table_data" v-if="$i18n.locale == 'fr' && data.transparence.trs_comparateurs_texte || $i18n.locale != 'fr' && data.transparence.trs_comparateurs_texte_en">
                                <span v-if="$i18n.locale == 'fr'" v-html="data.transparence.trs_comparateurs_texte"></span>
                                <span v-else v-html="data.transparence.trs_comparateurs_texte_en"></span>
                            </div>
                        </div>   
                        <div class="content-onglets table" v-if="data.transparence.trs_avis_ct || data.transparence.transparence_comparateur_avis_ct.length > 0">
                            <strong class="text-orange-no"> {{$t('medicaments_comparables')}} </strong>    
                            <table class="comparateur2 text-left" v-if="data.transparence.transparence_comparateur_avis_ct.length > 0">
                                <tr v-for="(item_TC_comparateur_Avis_CT, key_TC_comparateur_Avis_CT) in data.transparence.transparence_comparateur_avis_ct" :key="'key_TC_comparateur_Avis_CT_'+key_TC_comparateur_Avis_CT">
                                    <td v-if="key_TC_comparateur_Avis_CT == 0" style="width:40%;vertical-align: middle;text-align: left;" :rowspan="data.transparence.transparence_comparateur_avis_ct.length">
                                        {{$t(data.transparence['trs_avis_ct'])}}
                                    </td>
                                    <td style="text-align: center;">
                                        {{item_TC_comparateur_Avis_CT['comparateur']}}
                                    </td>
                                </tr>
                            </table> 
                            <table class="comparateur2 text-left" v-else-if="data.transparence.trs_avis_ct || data.transparence.trs_avis_ct != 'TRS_avis_ct_0'">
                                <tr>
                                    <td style="border-top:1px solid #e6e9ee; width:100%;vertical-align: middle;">
                                        {{$t(data.transparence.trs_avis_ct)}}
                                    </td>
                                </tr>
                            </table> 
                        </div>
                    </template>
                    <!--Link comparateurs_pertinents end--> 

                    <!--ISP start-->
                    <div class="content-onglets table" v-else-if="key == 'detail_ISP' && item.show">   
                        <template v-for="(item_TC_SMR, key_TC_SMR) in gen_smr(data.transparence.transparence_smr)">
                            <table class="comparateur2" v-for="(item_isp, key_isp) in item_TC_SMR.isp_json" :key="key_TC_SMR+'key_isp'+key_isp">
                                <tbody>
                                    <tr>
                                        <td width="50%">
                                            <strong> {{check_empty($i18n.locale == 'fr' ? item_TC_SMR['smr_title'] : item_TC_SMR['smr_title_en'])}}</strong>
                                        </td>
                                        <td style="vertical-align: middle;">
                                            <strong> {{item_isp['ISP_libelle'] ? $t('isp.'+item_isp['ISP_libelle']) : '-'}}</strong>
                                        </td>
                                    </tr>
                                    <tr v-if="$i18n.locale == 'fr' && item_isp['ISP_text'] || $i18n.locale != 'fr' && item_isp['ISP_text_en']">
                                        <td colspan="2" style="text-align: justify;">
                                            <span v-html="$i18n.locale == 'fr' ? item_isp['ISP_text'] : item_isp['ISP_text_en']"></span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </template>
                    </div>
                    <!--ISP end-->

                    <!--population cible start-->   
                    <div class="content-onglets" v-else-if="key == 'detail_Population_cible' && item.show">
                        <p v-if="$i18n.locale == 'fr'" v-html="data.transparence.trs_population_cible"></p>
                        <p v-else v-html="data.transparence.trs_population_cible_en"></p>
                    </div>
                    <!--population cible end-->  

                    <!--ResumeDiscussion start--> 
                    <template v-else-if="key == 'detail_ResumeDiscussion' && item.show">
                        <div class="content-onglets" v-for="(item_resume, key_resume) in data.transparence.resume_discussion" :key="'key_resume_'+key_resume">
                            <p v-if="$i18n.locale == 'fr'" v-html="item_resume['resume_fr']"></p>
                            <p v-else v-html="item_resume['resume_en']"></p>
                        </div>
                    </template>
                    <!--ResumeDiscussion end--> 

                    <!---TransparenceCompteRendu start --->
                    <div class="content-onglets table" v-else-if="key == 'detail_TransparenceCompteRendu' && item.show">    
                        <table class="comparateur2 text-left" v-for="(item_compte_rendu, key_compte_rendu) in data.transparence.transparence_compte_rendu" :key="'key_compte_rendu'+key_compte_rendu">                            
                            <tbody>
                                <tr>
                                    <th width="20%" style=" text-align: left;"> {{$t('Expertise externe')}} </th>
                                    <td> {{item_compte_rendu['external_expertise'] ? item_compte_rendu['external_expertise'] : "-"}} </td>
                                </tr> 
                                <tr>
                                    <th width="20%" style=" text-align: left;"> {{$t('Partie prenante')}} </th>
                                    <td> {{item_compte_rendu['patient_association'] ? item_compte_rendu['patient_association'] : "-"}} </td>
                                </tr>  
                            </tbody>
                        </table>
                    </div>
                    <!---TransparenceCompteRendu end --->

                    <!--synthes start-->  
                    <div class="content-onglets" v-else-if="key == 'detail_Synthes_en' && item.show">
                        <div class="box-toggle" v-for="(item_synths, key_synths) in data.transparence.synthese" :key="'key_synths_en'+key_synths">
                            <a class="title title-tb" @click="Synthese_en_toggle(key_synths)" style="cursor:pointer;">
                                <span v-html="item_synths['title_en']" class="mr-1"></span> 
                                <em class="fa fa-angle-down icon-arrow-updown"></em>
                            </a>
                            <div v-if="Synthese_en_show[key_synths] == 1" class="mt-3" style="background-color : #FEF0E6;">
                               <span v-html="item_synths['text_en'] ? item_synths['text_en'] : '-'"></span>
                            </div>
                        </div>    
                    </div>
                    <!--synthes end-->

                    <!--synthes start-->  
                    <div class="content-onglets" v-else-if="key == 'detail_Synthes' && item.show">
                        <div class="box-toggle" v-for="(item_synths, key_synths) in data.transparence.synthese" :key="'key_synths'+key_synths">                            
                            <a class="title-tb" @click="Synthese_toggle(key_synths)" style="cursor:pointer;">
                                <span v-html="item_synths['title']" class="mr-1"></span>
                                <em :class="Synthese_show[key_synths] == 1 ? 'fa fa-angle-up icon-arrow-updown' : 'fa fa-angle-down icon-arrow-updown'"></em>                               
                            </a>  
                            <div v-if="Synthese_show[key_synths] == 1" class="mt-3" style="background-color : #FEF0E6;">
                                <span v-html="item_synths['text'] ? item_synths['text'] : '-'"></span>
                            </div>
                        </div>
                    </div>
                    <!--synthes end-->

                    <!-- EssaisClinique start-->
                    <EssaisClinique v-else-if="key == 'detail_clinical_trials' && item.show" :datas="data.essais_clinique"/>
                    <!-- EssaisClinique end-->   

                    <!-- Indirect comparison start -->
                    <div class="content-onglets" v-else-if="key == 'detail_indirect_comparison' && item.show">
                        <template v-if="data.transparence.indirect_comparison_data">
                            <p><strong> {{$t('Indirect comparison')}} :</strong> {{$t(data.transparence['indirect_comparison_data'])}} </p>
                        </template>
                        <template v-if="data.transparence.acepted_ic">
                            <p><strong> {{$t('Taken into account')}} :</strong> {{$t(data.transparence['acepted_ic'])}} </p>
                        </template>
                        <template v-if="data.transparence.population_ic && $i18n.locale != 'en'">
                            <p v-html="data.transparence['population_ic']"></p>
                        </template>
                    </div>      
                    <!--  Indirect comparison end -->

                    <!-- EPI start -->
                    <div class="content-onglets table" v-else-if="key == 'detail_epi' && item.show">      
                        <table  class="comparateur2">
                            <thead>
                                <tr>
                                    <th class="first" width="50%"> {{$t('asmr.ASMR_demande_short')}} </th>
                                    <th class="last" width="50%"> {{$t('TRS_EPI_DEP_short')}} </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td> {{$t('yes_'+ data.transparence['trs_epi'])}} </td>
                                    <td> {{$t('yes_'+ data.transparence['trs_epi_dep'])}} </td>
                                </tr> 
                            </tbody>
                        </table>
                    </div>
                    <!-- EPI end -->

                    <!--EvaluationEconomic start-->
                    <EvaluationEconomic v-else-if="key == 'detail_EvaluationEconomic' && item.show" :data="data.transparence.evaluation_economic" />
                    <!--EvaluationEconomic end-->

                    <!--ITR start-->    
                    <div class="content-onglets table" v-else-if="key == 'detail_ITR' && item.show">
                        <table class="comparateur2 vertical-md" v-for="(item_itr, key_itr) in data.transparence.itr" :key="'ransparence_itr'+key_itr">
                            <thead>
                                <tr>
                                    <th width="100%" class="first last"  colspan="4"> {{$t('itr_Recevabilite')}} </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td colspan="4">
                                        <template v-if="item_itr['recevabilite'] == '-1'">
                                            {{$t("Non recevabilité")}}
                                        </template>
                                         <template v-else-if="item_itr['recevabilite'] == '0'">
                                            Recevabilité de la non infériorité
                                        </template>
                                         <template v-else-if="item_itr['recevabilite'] == 'x'">
                                            Recevabilité des données de supériorité
                                        </template>
                                    </td>
                                </tr>
                            </tbody>
                            <thead>
                                <tr>
                                    <th width="100%" class="first last" colspan="4"> {{$t('itr_Assessment')}} </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td  colspan="4">
                                        <template v-if="item_itr['assessment'] == 0">
                                            Sur le critère de jugement pertinent
                                        </template>
                                        <template v-else-if="item_itr['assessment'] == 1">
                                           Sur le critère de jugement de substitution
                                        </template>
                                        <template v-else>
                                           -
                                        </template>
                                    </td>
                                </tr>
                            </tbody>
                            <thead>
                                <tr>
                                    <th width="50%" class="first" colspan="2"> {{$t('itr_Relevant')}} </th>
                                    <th width="50%" class="last" colspan="2"> {{$t('itr_Substitution')}} </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <template v-if="item_itr['relevant'] == 0">
                                        <td>Nulle</td><td width=15%>0</td>
                                    </template>
                                    <template v-else-if="item_itr['relevant'] == 1">
                                        <td>Minime</td><td width=15%>1</td>
                                    </template>
                                    <template v-else-if="item_itr['relevant'] == 2">
                                        <td>Modérée</td><td width=15%>2</td>
                                    </template>
                                    <template v-else-if="item_itr['relevant'] == 3">
                                        <td>Majeure</td><td width=15%>3</td>
                                    </template>
                                    <template v-else>
                                        <td>-</td><td width=15%>-</td>
                                    </template>

                                    <template v-if="item_itr['substitution'] == -1">
                                        <td>Nulle</td><td width=15%>-1</td>
                                    </template>
                                    <template v-else-if="item_itr['substitution'] == 0">
                                        <td>Minime</td><td width=15%>0</td>
                                    </template>
                                    <template v-else-if="item_itr['substitution'] == 1">
                                        <td>Modérée</td><td width=15%>1</td>
                                    </template>
                                    <template v-else-if="item_itr['substitution'] == 2">
                                        <td>Majeure</td><td width=15%>2</td>
                                    </template>
                                    <template v-else>
                                        <td>-</td><td width=15%>-</td>
                                    </template>
                                </tr>
                            </tbody>
                            <thead>
                                <tr>
                                    <th width="100%" class="first last" colspan="4"> {{$t('itr_Modulation')}} </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td> {{$t('itr_Tolerance')}} </td>
                                    <td width=15%> {{item_itr['tolerance'] ? item_itr['tolerance']  : '-' }}</td>
                                    <td> {{$t('itr_Conditions')}} </td>
                                    <td width=15%>{{item_itr['conditions'] ? item_itr['conditions']  : '-' }}</td>
                                </tr>
                            </tbody>
                            <thead>
                                <tr>
                                    <th class="first last"> {{$t('itr_calculate')}} </th>
                                    <td>{{item_itr['calculate'] ? item_itr['calculate']  : '-' }}</td>
                                </tr>
                            </thead>
                            <thead>
                                <tr>
                                    <th class="first last"> {{$t('itr_result')}} </th>
                                    <td colspan="3"> {{item_itr['result_itr'] ? item_itr['result_itr']  : '-'}} </td>
                                </tr>
                            </thead>
                        </table>
                    </div>
                    <!--ITR end-->

                    <!-- linkBoitePresentation start -->
                    <linkBoitePresentation v-else-if="key == 'detail_Boite' && item.show" :data="data.data_links['BoitePresentation']" :trs_type_demande="data.transparence.trs_type_demandes" id="BoitePresentation"/>
                    <!-- linkBoitePresentation end -->

                    <!--Gravité ou rareté de la maladie que la spécialité est destinée à traiter start-->  
                    <div class="content-onglets" v-else-if="key == 'detail_gravite_ou_rarete_de_la_maladie' && item.show">
                        <p v-if="$i18n.locale == 'fr'" v-html="data.transparence['gravite_ou_rarete_de_la_maladie_fr']"></p>
                        <p v-else v-html="data.transparence['gravite_ou_rarete_de_la_maladie_en']"></p>
                    </div>
                    <!--Gravité ou rareté de la maladie que la spécialité est destinée à traiter end--> 

                    <!--Risque pour la santé des patients résultant d’un éventuel report de la mise en œuvre du traitement start-->  
                    <div class="content-onglets"  v-else-if="key == 'detail_risque_pour_la_sante_des_patients' && item.show">
                        <p v-if="$i18n.locale == 'fr'" v-html="data.transparence['risque_pour_la_sante_des_patients_fr']"></p>
                        <p v-else v-html="data.transparence['risque_pour_la_sante_des_patients_en']"></p>
                    </div>
                    <!--Risque pour la santé des patients résultant d’un éventuel report de la mise en œuvre du traitement end--> 

                    <!--Caractère innovant que la spécialité est susceptible d’apporter start-->   
                    <div class="content-onglets" v-else-if="key == 'detail_caractere_innovant_que_la_specialite' && item.show">
                        <p v-if="$i18n.locale == 'fr'" v-html="data.transparence['caractere_innovant_que_la_specialite_fr']"></p>
                        <p v-else v-html="data.transparence['caractere_innovant_que_la_specialite_en']"></p>
                    </div>
                    <!--Caractère innovant que la spécialité est susceptible d’apporter end--> 

                    <!--Efficacité et effets indésirables de la spécialité start-->   
                    <div class="content-onglets" v-else-if="key == 'detail_efficacite_et_effets_indesirables' && item.show">
                        <p v-if="$i18n.locale == 'fr'" v-html="data.transparence['efficacite_et_effets_indesirables_fr']"></p>
                        <p v-else v-html="data.transparence['efficacite_et_effets_indesirables_en']"></p>
                    </div>
                    <!--Efficacité et effets indésirables de la spécialité end--> 

                    <!--Conclusion start-->   
                    <div class="content-onglets" v-else-if="key == 'detail_conclusion' && item.show">
                        <p v-if="$i18n.locale == 'fr'" v-html="data.transparence['conclusion_fr']"></p>
                        <p v-else v-html="data.transparence['conclusion_en']"></p>
                    </div>
                    <!--Conclusion end--> 

                    <!-- Section Detail End -->

                </div>
            </template>

            <!-- Link Section Start-->       
            <LinkAgency v-for="(data_agency,Agency) in data.data_links" :data="data_agency" :link_agency="Agency" :key="Agency" />
            <!-- Link Section End -->
            
            <!-- Link Section End -->

        </div>
        <!-- Right Section End -->
    </div>
</template>

<script>
import LinkUpload from '../LinkUpload.vue'
import LeftSection from '../LeftSection.vue'
import TreatmentLine from '../treatment_line.vue'
import EvaluationEconomic from '../evaluation_economic.vue'
import KeyDocument from '../key_document.vue'
import EssaisClinique from '../../elements/essais_clinique_detail.vue'
import Conclusion from '../conclusion.vue'
import LinkAgency from '../LinkAgency.vue'
import linkBoitePresentation from '../linkBoitePresentation.vue'
import Indication from '../Indication.vue'
import { DDMMMYY, check_empty, strippedContent, check_detail_conclusion, key_documents } from '../../../utils'

export default {
    name: 'transparence',
    components : {
        LinkUpload,
        LeftSection,
        TreatmentLine,
        EvaluationEconomic,
        KeyDocument,
        EssaisClinique,
        Conclusion,
        LinkAgency,
        linkBoitePresentation,
        Indication
    },
    data(){
        return {
            list_check_box : {
                // show -> show detail, 
                // enable -> show [check box] on left box, 
                // orange_text -> if true: color of title text is orange

                detail_general_information : {'title' : 'information generale', 'show' : true, 'enable' : false, 'orange_text':false },
                detail_ATU : {'title' : 'ATU/AAP trs', 'show' : true, 'enable' : false, 'orange_text':false },
                detail_PostAtu : {'title' : 'post_atu', 'show' : true, 'enable' : false, 'orange_text':false },
                detail_indication : {'title' : 'indication', 'show' : true, 'enable' : false, 'orange_text':false },
                detail_indication_non_prise_en_charge_temporaire : {'title' : 'Indication de l’AMM non concernée par la demande de prise en charge temporaire', 'show' : true, 'enable' : false, 'orange_text':false },
                treatment_line : {'title' : 'population', 'show' : true, 'enable' : false, 'orange_text':false },
                detail_Conclusion : {'title' : 'Rationale and Commentary', 'show' : true, 'enable' : false, 'orange_text':true },
                detail_Context : {'title' : 'transparence.contexte', 'show' : true, 'enable' : false, 'orange_text':false },
                detail_besoin_medical : {'title' : 'Besoin medical', 'show' : true, 'enable' : false, 'orange_text':false },
                detail_data_package : {'title' : 'Analyse des données disponibles', 'show' : true, 'enable' : false, 'orange_text':false},
                detail_SMR : {'title' : 'SMR_full', 'show' : true, 'enable' : false, 'orange_text':false },
                detail_ASMR : {'title' : 'ASMR_full', 'show' : true, 'enable' : false, 'orange_text':false },
                detail_TransparenceStrategieTherapeutique : {'title' : 'Strategie_Therapeutique', 'show' : true, 'enable' : false, 'orange_text':false },
                detail_DataBlock : {'title' : 'Audition', 'show' : true, 'enable' : false, 'orange_text':false },
                detail_comparateurs_pertinents : {'title' : 'C_P', 'show' : true, 'enable' : false, 'orange_text':false },
                detail_ISP : {'title' : 'ISP_full', 'show' : true, 'enable' : false, 'orange_text':false },
                detail_Population_cible : {'title' : 'trs_population_cible', 'show' : true, 'enable' : false, 'orange_text':false },
                detail_ResumeDiscussion : {'title' : 'resume_discussion', 'show' : true, 'enable' : false, 'orange_text':false },
                detail_TransparenceCompteRendu : {'title' : 'Compte-rendu', 'show' : true, 'enable' : false, 'orange_text':false },
                detail_Synthes_en : {'title' : 'briefing_note2', 'show' : true, 'enable' : false, 'orange_text':false },
                detail_Synthes : {'title' : 'briefing_note', 'show' : true, 'enable' : false, 'orange_text':false },
                detail_indirect_comparison : {'title' : 'Indirect comparison', 'show' : true, 'enable' : false, 'orange_text':false },
                detail_epi : {'title' : 'EPI', 'show' : true, 'enable' : false, 'orange_text':false },
                detail_clinical_trials : {'title' : 'Clinical trials', 'show' : true, 'enable' : false, 'orange_text':true },
                detail_EvaluationEconomic : {'title' : 'Economic evaluation', 'show' : true, 'enable' : false, 'orange_text':true },
                detail_ITR : {'title' : 'itr', 'show' : true, 'enable' : false, 'orange_text':false },
                detail_Boite : {'title' : 'Boites_presentation', 'show' : true, 'enable' : false, 'orange_text':false },
                detail_gravite_ou_rarete_de_la_maladie : {'title' : 'Gravité ou rareté de la maladie que la spécialité est destinée à traiter', 'show' : true, 'enable' : false, 'orange_text':false },
                detail_risque_pour_la_sante_des_patients : {'title' : 'Risque pour la santé des patients résultant d’un éventuel report de la mise en œuvre du traitement', 'show' : true, 'enable' : false, 'orange_text':false },
                detail_caractere_innovant_que_la_specialite : {'title' : 'Caractère innovant que la spécialité est susceptible d’apporter', 'show' : true, 'enable' : false, 'orange_text':false },
                detail_efficacite_et_effets_indesirables : {'title' : 'Efficacité et effets indésirables de la spécialité', 'show' : true, 'enable' : false, 'orange_text':false },
                detail_conclusion : {'title' : 'Conclusion', 'show' : true, 'enable' : false, 'orange_text':false },
                detail_key_documents : {'title' : 'Key documents', 'show' : true, 'enable' : false, 'orange_text':false}

            },

            SMR_text_show : {},
            Synthese_show : {},
            Synthese_en_show : {},
            show_data_phase_contradictoire : {},
            ASMR_text_show : {},
            key_transparence_stragie_therepeutique_asmr : {}

        }
    },
    created(){
        // condition to show check box in left section [enable]
        if(this.data && this.data.transparence){  

            this.show_block = this.view_enabled ?  this.view_enabled : [] 
            if(this.data.transparence.atu_france.length > 0){
                this.list_check_box['detail_ATU'].enable = true
                this.data.transparence.atu_france.forEach(v => {
                    if(v.post_atu.length > 0){
                        this.list_check_box['detail_PostAtu'].enable = true
                    }
                });
            }           

            if((this.data['indication_fr'] && this.$i18n.locale === 'fr') || (this.data['indication_en'] && this.$i18n.locale !== 'fr')){
                    this.list_check_box['detail_indication'].enable = true
            }

            if ((this.$i18n.locale == 'fr' && this.data.transparence['indication_non_prise_en_charge_temporaire_fr']) || 
                (this.$i18n.locale != 'fr' && this.data.transparence['indication_non_prise_en_charge_temporaire_en'])) {
                    this.list_check_box['detail_indication_non_prise_en_charge_temporaire'].enable = true
            }

            if(this.data.treatment_line || this.data.specificity || this.data.administration){
                    this.list_check_box['treatment_line'].enable = true
            }

            if(this.data.transparence.trs_date_depot_ct || this.data.transparence.trs_type_amm || this.data.transparence.trs_date_type_amm ||
                this.data.transparence.trs_modify_smr_asmr || this.data.transparence.trs_cpd || this.data.transparence.trs_cpd_en || 
                this.data.transparence.trs_commentaires || this.data.transparence.trs_demand_withdrawn || this.data.transparence.nd ||
                this.data.transparence.trs_prise_en_charge_precoce){
                    this.list_check_box['detail_general_information'].enable = true
            }

            if(this.check_detail_conclusion(this.data, this.$i18n.locale)) 
            { 
                this.list_check_box['detail_Conclusion'].enable = true
            }

            if(this.$i18n.locale == 'fr' && this.data.transparence.trs_context_fr || 
                this.$i18n.locale != 'fr' && this.data.transparence.trs_context_en){
                this.list_check_box['detail_Context'].enable = true
            }

            if(this.data.transparence.transparence_smr.length > 0 && this.data.transparence.transparence_smr[0].besoin_medical_json && this.data.transparence.transparence_smr[0].besoin_medical_json.length > 0){
                this.list_check_box['detail_besoin_medical'].enable = true
            }

            if((this.$i18n.locale != 'fr' && this.data.transparence.data_package_en) || 
                (this.$i18n.locale == 'fr' && this.data.transparence.data_package_fr)){
                this.list_check_box['detail_data_package'].enable = true
            }

            if(Object.keys(this.gen_smr(this.data.transparence.transparence_smr)).length > 0){
                this.list_check_box['detail_SMR'].enable = true

                // length of SMR_text_show
                for(let key in this.data.transparence.transparence_smr){
                    this.SMR_text_show[key] = 0
                }
            }

            if(Object.keys(this.gen_asmr(this.data.transparence.transparence_smr)).length > 0){
                this.list_check_box['detail_ASMR'].enable = true

                // length of ASMR_text_show
                for(let key in this.data.transparence.transparence_smr){
                    this.ASMR_text_show[key] = 0
                }
            }

            if(this.data.transparence.transparence_strategie_therapeutique.length > 0){
                for(let key in this.data.transparence.transparence_strategie_therapeutique){
                    let item_transparence_stragie_therepeutique = this.data.transparence.transparence_strategie_therapeutique[key]
                    if(this.$i18n.locale == 'fr' && item_transparence_stragie_therepeutique['title'] || 
                        this.$i18n.locale != 'fr' && item_transparence_stragie_therepeutique['title_en'] || 
                        item_transparence_stragie_therepeutique['asmr_id'] || 
                        this.$i18n.locale == 'fr' && item_transparence_stragie_therepeutique['text'] ||
                        this.$i18n.locale != 'fr' && item_transparence_stragie_therepeutique['text_en']){
                            this.list_check_box['detail_TransparenceStrategieTherapeutique'].enable = true
                            break
                        }
                }
            }

            if(this.data.transparence.audition && this.data.transparence.audition.length > 0){
                this.list_check_box['detail_DataBlock'].enable = true

                // length of show_data_phase_contradictoire
                for(let key in this.data.transparence.audition){
                    this.show_data_phase_contradictoire[key] = 0
                }
            }

            if(this.data.transparence.comparateurs_pertinent.length > 0 || 
                (this.data.transparence.trs_comparateurs_texte && this.$i18n.locale == 'fr') || 
                (this.data.transparence.trs_Comparateurs_texte_en && this.$i18n.locale != 'fr') ||
                (this.data.transparence.trs_avis_ct && this.data.transparence.trs_avis_ct != 'TRS_avis_ct_0') || 
                this.data.transparence.transparence_comparateur_avis_ct.length > 0
                ){
                this.list_check_box['detail_comparateurs_pertinents'].enable = true
            }

            if(this.data.transparence.transparence_smr.length > 0){
                let count = 0 
                for(let key in this.data.transparence.transparence_smr){
                    if(this.data.transparence.transparence_smr[key].isp_json && this.data.transparence.transparence_smr[key].isp_json.length > 0){
                        count += 1
                    }
                }

                if(count > 0){
                    this.list_check_box['detail_ISP'].enable = true
                }
                
            }

            if(this.$i18n.locale == 'fr' && this.data.transparence.trs_population_cible ||
                this.$i18n.locale != 'fr' && this.data.transparence.trs_population_cible_en){
                this.list_check_box['detail_Population_cible'].enable = true
            }

            if(this.data.transparence.resume_discussion.length > 0){
                for(let key in this.data.transparence.resume_discussion){
                    if(this.$i18n.locale == 'fr' && this.data.transparence.resume_discussion[key].resume_fr){
                        this.list_check_box['detail_ResumeDiscussion'].enable = true
                        break;
                    }
                    else if(this.$i18n.locale != 'fr' && this.data.transparence.resume_discussion[key].resume_en){
                        this.list_check_box['detail_ResumeDiscussion'].enable = true
                        break;
                    }
                }
                
            }

            if(this.data.transparence.transparence_compte_rendu.length > 0){
                this.list_check_box['detail_TransparenceCompteRendu'].enable = true
            }

            if(this.data.transparence.synthese.length > 0){
                let check = false
                for(let key in this.data.transparence.synthese){
                    if(!check){
                        if(this.$i18n.locale == 'en' && (this.data.transparence.synthese[key].title_en || this.data.transparence.synthese[key].text_en)){
                            this.list_check_box['detail_Synthes_en'].enable = true
                            check = true
                        }
                        else if(this.$i18n.locale == 'fr' && (this.data.transparence.synthese[key].title || this.data.transparence.synthese[key].text)){
                            this.list_check_box['detail_Synthes'].enable = true
                            check = true
                        }
                    }
                    this.Synthese_en_show[key] = 0
                    this.Synthese_show[key] = 0
                }
            }

            if(this.data.essais_clinique && this.data.essais_clinique.length > 0){
                this.list_check_box['detail_clinical_trials'].enable = true
            }

            if(this.data.transparence.indirect_comparison_data || this.data.transparence.population_ic || this.data.transparence.acepted_ic){
                this.list_check_box['detail_indirect_comparison'].enable = true
            }

            if(this.data.transparence.trs_epi || this.data.transparence.trs_epi_dep){
                this.list_check_box['detail_epi'].enable = true
            }

            if(this.data.transparence.evaluation_economic.length > 0) { 
                    this.list_check_box['detail_EvaluationEconomic'].enable = true
            }

            if(this.data.transparence.itr.length > 0){
                    this.list_check_box['detail_ITR'].enable = true
            }             
            
            if(key_documents(this.data.key_documents) && key_documents(this.data.key_documents).length > 0){ 
                    this.list_check_box['detail_key_documents'].enable = true
            }

            if(this.data.data_links && this.data.data_links['BoitePresentation']){
                    this.list_check_box['detail_Boite'].enable = true
            }

            if ((this.$i18n.locale == 'fr' && this.data.transparence['gravite_ou_rarete_de_la_maladie_fr']) || 
                (this.$i18n.locale != 'fr' && this.data.transparence['gravite_ou_rarete_de_la_maladie_en'])) {
                    this.list_check_box['detail_gravite_ou_rarete_de_la_maladie'].enable = true
            }
                        
            if ((this.$i18n.locale == 'fr' && this.data.transparence['risque_pour_la_sante_des_patients_fr']) || 
                (this.$i18n.locale != 'fr' && this.data.transparence['risque_pour_la_sante_des_patients_en'])) {
                    this.list_check_box['detail_risque_pour_la_sante_des_patients'].enable = true
            }        
            if ((this.$i18n.locale == 'fr' && this.data.transparence['caractere_innovant_que_la_specialite_fr']) || 
                (this.$i18n.locale != 'fr' && this.data.transparence['caractere_innovant_que_la_specialite_en'])) {
                    this.list_check_box['detail_caractere_innovant_que_la_specialite'].enable = true
            }          
            if ((this.$i18n.locale == 'fr' && this.data.transparence['efficacite_et_effets_indesirables_fr']) || 
                (this.$i18n.locale != 'fr' && this.data.transparence['efficacite_et_effets_indesirables_en'])) {
                    this.list_check_box['detail_efficacite_et_effets_indesirables'].enable = true
            }           
            if ((this.$i18n.locale == 'fr' && this.data.transparence['conclusion_fr']) || 
                (this.$i18n.locale != 'fr' && this.data.transparence['conclusion_en'])) {
                    this.list_check_box['detail_conclusion'].enable = true
            }
        }
    },
    methods : {        
        DDMMMYY,
        check_empty,
        strippedContent,
        check_detail_conclusion,
        key_documents,
        SMR_text_toggle(key_smr){
            // console.log(this.SMR_text_show[key_smr], key_smr)
            if(this.SMR_text_show[key_smr] == 1){
                this.SMR_text_show[key_smr] = 0
            }else{
                this.SMR_text_show[key_smr] = 1
            }
            this.$forceUpdate();
        },
        ASMR_text_toggle(key_asmr){
            // console.log(this.ASMR_text_show[key_asmr], key_asmr)
            if(this.ASMR_text_show[key_asmr] == 1){
                this.ASMR_text_show[key_asmr] = 0
            }else{
                this.ASMR_text_show[key_asmr] = 1
            }
            this.$forceUpdate();
        },
        show_data_phase_contradictoire_toggle(key){
            if(this.show_data_phase_contradictoire[key] == 1){
                this.show_data_phase_contradictoire[key] = 0
            }else{
                this.show_data_phase_contradictoire[key] = 1
            }
            this.$forceUpdate();
        },
        Synthese_en_toggle(key){
            if(this.Synthese_en_show[key] == 1){
                this.Synthese_en_show[key] = 0
            }else{
                this.Synthese_en_show[key] = 1
            }
            this.$forceUpdate();
        },
        Synthese_toggle(key){
            if(this.Synthese_show[key] == 1){
                this.Synthese_show[key] = 0
            }else{
                this.Synthese_show[key] = 1
            }
            this.$forceUpdate();
        },
        gen_smr(param){
             let new_smr = {}
             for(let key in param){
                 if(param[key].smr_id && !Object.keys(new_smr).includes(param[key].smr_id)){
                     new_smr[param[key].smr_id] = param[key]
                 }
             }
             return new_smr
        },
        gen_asmr(param){
            let new_asmr = {}
            for(let key in param){
                if(param[key].asmr_id && !Object.keys(new_asmr).includes(param[key].asmr_id)){
                    new_asmr[param[key].asmr_id] = param[key]
                 }
             }
             return new_asmr
        }
    },
    computed : {
        data(){
            return this.$store.getters['detail/data']
        },
        show_block : {
            set(val) { this.$store.dispatch('detail/set_show_block', val)},
            get() {return this.$store.getters['detail/show_block']}
        },
        view_enabled() {  
            return this.$store.getters['detail/view_enabled']        
        },
        access_agency(){
            return this.$store.getters['detail/access_agency']  
        }
    },
}
</script>

<style scoped>
.table th, .table td {
    padding: 15px;
}
a{
    color:#000;
}
table.comparateur2  > tr:first-child > th, table.comparateur2  > tr:first-child > td{
    border-top: 1px solid #e6e9ee;
}

table.comparateur2 .noborder > tr:first-child > td{
    border-top : 0px;
}
</style>
